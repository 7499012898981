import React, {useState} from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { FormWrapper } from "./Contact.styles"
import {Container, Col, Row, Card} from "react-bootstrap";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { Link } from "gatsby";


function CareerForm() {
  const [contact, setContact] = useState();
  const [state, handleSubmit] = useForm("xayvywwn");
  if (state.succeeded) {
      return (
        <p>Thanks for contacting us! We will get in touch with you shortly.</p>
      );
  }
  return (
    <FormWrapper>
           
                <p className="sub-title">Get in Touch</p>
                <form onSubmit={handleSubmit} method="post" enctype="multipart/form-data">
         <div className="form-group">
                  <label htmlFor="name">Your Full Name</label>
                  <input type="text" className="form-control" id="name" name="name" />
                  <ValidationError prefix="Text" field="name" errors={state.errors}/>
          </div>
          <div className="form-group">
                <label htmlFor="email"> Your Business Email</label>
                <input id="email" type="email" name="email"/>
                <ValidationError prefix="Email" field="email" errors={state.errors} />
          </div>
          <div className="form-group">
                <label htmlFor="company"  >Your Company</label>
                <input type="text" className="form-control" id="company" name="company" aria-describedby="emailHelp" />
           </div>
          <div className="form-group">
                  <label htmlFor="contact" >Contact Number</label>
                  <PhoneInput defaultCountry="BH" id="contact" name="contact" type="text" value={contact} onChange={setContact}/>
          </div>
          <div className="form-group">
                  <label htmlFor="message" >Message</label>
                  <textarea id="message" name="message" />
                  <ValidationError prefix="Message" field="message" errors={state.errors} />
          </div>
          <label>
    Your file:
    <input type="file" name="upload"/>
  </label>
          
     
      
           <button type="submit" disabled={state.submitting}> Submit </button>
    </form>
        </FormWrapper>
    
     

  );
}

export default CareerForm;